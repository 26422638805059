import React from "react";
import { Row, Col, Modal, ModalBody, Button } from "reactstrap";
import {
  ErrorCircle,
  LoadingIcon,
  SuccessIcon,
  TimeoutIcon,
  WarningIcon,
} from "../../commons/components/Icons";

interface Props {
  errCode?: string;
  backClick: any;
  products_count: number;
  errorMessage: string;
  isPolling: boolean;
  orderId: number;
  linkId: number;
  userInputType: string;
  redemption_check: any;
  send_to_self: number;
  isGvLink: boolean;
}

const anchorStyle = {
  color: "#0172CB",
};

class RedemptionError extends React.Component<Props> {
  getErrData = () => {
    const {
      errCode = "",
      products_count = 0,
      errorMessage = "",
      isPolling,
      userInputType,
      redemption_check,
      send_to_self,
      isGvLink,
    } = this.props;
    let errData;
    switch (errCode) {
      case "LOW_BALANCE":
        errData = {
          icon: <WarningIcon />,
          title:
            "Error : #001 : The link is currently unavailable for redemption!",
          subTitle: (
            <>
              <div>
                Please check back in 24-48 hours or write to{" "}
                <a
                  href="mailto:cs@giift.com"
                  target="_blank"
                  style={anchorStyle}
                >
                  cs@giift.com
                </a>
              </div>
            </>
          ),
          backBtn: false,
        };
        break;
      case "PENDING":
        errData = {
          icon: <SuccessIcon />,
          title: "Your order has been placed successfully!",
          subTitle: (
            <>
              <div>
                {redemption_check && !isGvLink
                  ? `You will receive the voucher code via ${userInputType} within the next 24-48 hours.`
                  : "Please revisit the link after 24 hours."}
              </div>
            </>
          ),
          backBtn: false,
        };
        break;
      case "PRODUCT_NOT_FOUND":
        {
          if (products_count > 1) {
            return (errData = {
              icon: <WarningIcon />,
              title: "The product is currently unavailable for redemption!",
              subTitle: "Please try redeeming something else from the list.",
              backBtn: true,
            });
          } else {
            return (errData = {
              icon: <WarningIcon />,
              title: "The product is currently unavailable!",
              subTitle: (
                <>
                  <div>
                    Please check back in the same link after some time or write
                    to{" "}
                    <a
                      href="mailto:cs@giift.com"
                      target="_blank"
                      style={anchorStyle}
                    >
                      cs@giift.com
                    </a>
                  </div>
                </>
              ),
              backBtn: false,
            });
          }
        }
        break;
      case "LINK_CANCELLED":
        errData = {
          icon: <ErrorCircle />,
          title: "This Xoxo Link has been cancelled by the sender!",
          subTitle: (
            <>
              <div>
                This is no longer available for redemption. Please write to{" "}
                <a
                  href="mailto:cs@giift.com"
                  target="_blank"
                  style={anchorStyle}
                >
                  cs@giift.com
                </a>{" "}
                for any queries.
              </div>
            </>
          ),
          backBtn: false,
        };
        break;
      case "LINK_EXPIRED":
        errData = {
          icon: <TimeoutIcon />,
          title: "This Xoxo Link is expired!",
          subTitle: "This is no longer available for redemption.",
          backBtn: false,
        };
        break;
      case "GV_LINK_FAILED":
        errData = {
          icon: <LoadingIcon />,
          title: errorMessage,
          subTitle: (
            <div>{isPolling ? "Refreshing status, please wait..." : null}</div>
          ),
          backBtn: false,
        };
        break;
      case "GV_LINK_CANCELLED":
        errData = {
          icon: <ErrorCircle />,
          title: errorMessage,
          subTitle: "",
          backBtn: false,
        };
        break;
      case "GV_LINK_EXPIRED":
        errData = {
          icon: <ErrorCircle />,
          title: errorMessage,
          subTitle: "",
          backBtn: false,
        };
        break;
      case "CrossCurrencyCode":
        errData = {
          icon: <ErrorCircle />,
          title: "Error in redeeming xoxo code.",
          subTitle: (
            <>
              <div>
                Uh oh! Something went wrong. But don't worry, please reach out
                to{" "}
                <a
                  href="mailto:cs@giift.com"
                  target="_blank"
                  style={anchorStyle}
                >
                  cs@giift.com
                </a>{" "}
                with this error code (ERFOREXLINKCODE-1). We got your back!
              </div>
            </>
          ),
          backBtn: false,
        };
        break;
      case "":
        errData = {
          icon: <LoadingIcon />,
          title: "",
          subTitle: "",
          backBtn: false,
        };
      default:
        errData = {
          icon: <ErrorCircle />,
          title: errCode,
          subTitle: "",
          backBtn: false,
        };
    }
    return errData;
  };

  renderErrorMsg = () => {
    const { errCode = "", orderId = "", linkId = "" } = this.props;
    const errData = this.getErrData() || {
      icon: <LoadingIcon />,
      title: "",
      subTitle: "",
      backBtn: false,
    };
    return (
      <div className="my-4">
        {errCode.length > 0 ? (
          <>
            <h1 className="mb-4">{errData.icon}</h1>
            <div className="fw-500 fs-16 mb-2 ">{errData.title}</div>
            <div className="pb-1">
              {" "}
              {linkId
                ? `Order Reference ID: ${linkId}`
                : orderId
                ? `Order Reference ID: ${orderId}`
                : ""}
            </div>

            <div>{errData.subTitle}</div>
            {errData.backBtn ? (
              <Button
                className="mt-4 back-to-vouchers"
                color="default"
                onClick={() => this.props.backClick()}
              >
                Back to vouchers
              </Button>
            ) : null}
          </>
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <Modal
        isOpen={true}
        className="modal-wrapper error-modal"
        backdrop={false}
      >
        <ModalBody className="text-center modal-padding">
          <Row>
            <Col className="text-center">{this.renderErrorMsg()}</Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default RedemptionError;
